<script setup lang="ts">
import { ref, defineAsyncComponent, computed } from "vue";
import { useAlertStore } from "@/stores/alert";

const alertStore = useAlertStore();
const { alertState, unmountAlertModal } = alertStore;

type ModalComponent = typeof alertState.alertComponent | null;

const alertComponent = ref<ModalComponent>(null);
alertComponent.value = alertState.alertComponent;

const component = computed(() => {
    const alertComponentPath = alertState.alertComponent;
    return defineAsyncComponent(
        () => import(`@/components/templates/alerts/${alertComponentPath}.vue`),
    );
});
</script>

<template>
    <Teleport to="body" v-if="alertState.alertOpen">
        <div class="alert-modal-container" @click.self="unmountAlertModal">
            <div class="alert-modal-component-container" tabindex="-1">
                <Transition name="fade">
                    <component :is="component" />
                </Transition>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
$cls: alert-modal;

.#{$cls}-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;

    .#{$cls}-component-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: auto;
        z-index: 1000;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>
