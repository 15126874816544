<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const isOffline = ref(!window.navigator.onLine);

const warningOfflineState = (isWarning: boolean) => () => {
    isOffline.value = isWarning;
};

onMounted(() => {
    window.addEventListener("online", warningOfflineState(false));
    window.addEventListener("offline", warningOfflineState(true));
});

onUnmounted(() => {
    window.removeEventListener("online", warningOfflineState(false));
    window.removeEventListener("offline", warningOfflineState(true));
});
</script>

<template>
    <div class="offline-warning-container" v-if="isOffline">
        서버와의 접속이 끊어졌습니다. 인터넷 연결을 확인해주세요
    </div>
</template>

<style lang="scss" scoped>
$cls: offline-warning;

.#{$cls}-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 1.6rem;
    background-color: $gray-scale-gray-700;
    color: $white;
    font-size: 1.6rem;
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
</style>
