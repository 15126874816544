import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
export default {
    install(app) {
        app.config.globalProperties.$dayjs = dayjs;
        app.config.globalProperties.$utc = utc;
        app.config.globalProperties.$timezone = timezone;
        app.provide("dayjs", dayjs);
        app.provide("utc", utc);
        app.provide("timezone", timezone);
    },
};
