<script setup lang="ts">
import { ref, computed } from "vue";

import { navigationTemplateRouterLists } from "../resources/NavigationTemplate";
import MainAppIcon from "@/components/atoms/svg/MainAppIcon.vue";

const currentActiveRouterIndex = ref(1);

const activeRouterColor = computed(() => {
    return (routerIndex: number) => {
        return routerIndex === currentActiveRouterIndex.value ? "#304daf" : "#6D7D93";
    };
});

const navigatePage = (routerIndex: number) => {
    currentActiveRouterIndex.value = routerIndex;
};
</script>

<template>
    <section class="nav-section">
        <div class="nav-container">
            <div class="nav-home-logo">
                <router-link to="/main">
                    <MainAppIcon :width="38" :height="38" />
                </router-link>
            </div>
            <div class="nav-menu-section">
                <ul class="nav-menu-lists">
                    <router-link
                        v-for="router in navigationTemplateRouterLists"
                        :key="router.id"
                        :to="router.path"
                        @click="navigatePage(router.id)"
                    >
                        <li>
                            <component
                                :is="router.iconName"
                                :width="router.width"
                                :height="router.height"
                                :color="activeRouterColor(router.id)"
                            />
                        </li>
                    </router-link>
                </ul>
            </div>
            <!-- <div class="nav-setting-container" @click="settingDashboard">
                <div class="nav-setting-icon">
                    <SettingNavIcon :width="24" :height="24" :color="'#6D7D93'" />
                </div>
            </div> -->
        </div>
    </section>
</template>

<style lang="scss" scoped>
$cls: nav;

.#{$cls}-section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    // height: 100vh;
    width: 9rem;

    .#{$cls}-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;

        .#{$cls}-home-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 9rem;
            padding: 1rem;
            cursor: pointer;
        }

        .#{$cls}-menu-section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-grow: 1;
            width: 100%;
            height: 100%;
            cursor: pointer;

            .#{$cls}-menu-lists {
                width: 100%;
                height: auto;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 8rem;

                    &:hover {
                        background-color: $gray-scale-gray-100;
                    }

                    &:active {
                        border-left: 0.3rem solid $bg-navigate-blue;
                    }
                }
            }
        }

        .#{$cls}-setting-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 9rem;
            padding: 1rem;
            cursor: pointer;
        }
    }
}
</style>
