<script setup lang="ts"></script>

<template>
    <section class="auth-layout">
        <div class="auth-layout-section">
            <div class="auto-layout-logo">
                <img src="/assets/img/logo_susimdal.svg" alt="logo" class="auth-layout-logo-img" />
            </div>
            <header class="auth-layout-header">
                <h1 class="auth-layout-service-title">로그인</h1>
                <p class="auth-layout-service-description">
                    수심달 Dashboard 서비스에 오신 것을 환영합니다.
                </p>
            </header>
            <slot></slot>
        </div>
    </section>
</template>

<style lang="scss" scoped>
$cls: auth-layout;

.#{$cls} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#f8b4b7, #f4f4f4);
}

.#{$cls}-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 3rem;

    .#{$cls}-logo {
        &-img {
            width: 15.8rem;
            height: 4.5rem;
        }
    }

    .#{$cls}-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6rem;

        .#{$cls}-service-title {
            font-size: 3.6rem;
            font-weight: 600;
        }

        .#{$cls}-service-description {
            font-size: 2rem;
            font-weight: 400;
        }
    }
}
</style>
