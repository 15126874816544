import { createRouter, createWebHistory } from "vue-router";

/** layout */
import DefaultLayout from "@/layouts/TheDefaultLayout.vue";
import AuthLayout from "@/layouts/TheAuthLayout.vue";

/** page */
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            redirect: "/auth/login",
        },
        {
            path: "/main",
            name: "main",
            component: () => import(`@/views/AcademyDashboardMain.vue`),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/rank",
            name: "rank",
            component: () => import(`@/views/AcademyDashboardRank.vue`),
            meta: {
                requiresAuth: true,
                layout: DefaultLayout,
            },
        },
        {
            path: "/auth",
            component: () => import(`@/views/AcademyDashboardLogin.vue`),
            children: [
                {
                    path: "login",
                    name: "login",
                    component: () => import(`@/components/templates/DashboardLoginTemplate.vue`),
                    meta: {
                        requiresAuth: false,
                        layout: AuthLayout,
                    },
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    const accesToken = localStorage.getItem("userAccessToken");
    if (!accesToken) {
        if (to.meta.requiresAuth) {
            next("/auth/login");
        } else {
            next();
        }
    } else {
        if (to.name === "login") {
            next("/main");
        } else {
            next();
        }
    }
});

export default router;
