import RankPageNavIcon from "../atoms/svg/RankPageNavIcon.vue";
import MainPageNavIcon from "../atoms/svg/MainPageNavIcon.vue";

export const navigationTemplateRouterLists = [
    {
        id: 1,
        name: "main",
        path: "/main",
        iconName: MainPageNavIcon,
        width: 24,
        height: 24,
    },
    {
        id: 2,
        name: "rank",
        path: "/rank",
        iconName: RankPageNavIcon,
        width: 32,
        height: 32,
    },
];
