import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/styles/common/index.scss";
import "@egjs/vue3-flicking/dist/flicking.css";
import "floating-vue/dist/style.css";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "@/routers";
import Flicking from "@egjs/vue3-flicking";
import FloatingVue from "floating-vue";
import dayjs from "@/plugins/dayjs";

const app = createApp(App);

app.use(FloatingVue);
app.use(dayjs);
app.use(createPinia());
app.use(router);
app.component("TheFlicking", Flicking);
app.mount("#app");
