import { ref } from "vue";
import { defineStore } from "pinia";

import type { AlertState, ComponentPropsInAlert } from "./models";

export const useAlertStore = defineStore("alert", () => {
    const alertState = ref<AlertState<keyof ComponentPropsInAlert>>({
        alertOpen: false,
        alertComponent: null,
        alertProps: null,
    });

    const onmountAlertModal = <T extends keyof ComponentPropsInAlert>(
        component: T,
        props: ComponentPropsInAlert[T],
    ) => {
        alertState.value.alertOpen = true;
        alertState.value.alertComponent = component;
        alertState.value.alertProps = props ? { ...props } : null;
    };

    const unmountAlertModal = () => {
        alertState.value.alertOpen = false;
        alertState.value.alertComponent = null;
        alertState.value.alertProps = null;
    };

    return { alertState, onmountAlertModal, unmountAlertModal };
});
